import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom'; // Import useNavigate for back navigation
import axios from 'axios';
import { Button, Card, Container, Col, Row } from 'react-bootstrap';
import { ArrowLeft } from 'react-bootstrap-icons';
import './TodoDetail.css';
import AppNavbar from '../components/Navbar';

const TodoDetail = () => {
    const { id } = useParams();
    const navigate = useNavigate(); // Use useNavigate hook for navigation
    const [todo, setTodo] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchTodo = async () => {
            try {
                const response = await axios.get(`https://api.gmcobel.com/todos/${id}`);
                setTodo(response.data);
                setLoading(false);
            } catch (err) {
                setError(err);
                setLoading(false);
            }
        };

        fetchTodo();
    }, [id]);

    if (loading) return <div>Loading...</div>;
    if (error) return <div>Error fetching todo</div>;

    return (
        <div className="todo-detail">
            <AppNavbar />
            <Container>
                <Row>
                    <Col>
                        <Button variant="outline-light" onClick={() => navigate('/')}>
                            <ArrowLeft />
                        </Button>
                    </Col>
                </Row>
                <Row className="justify-content-md-center">
                    <h1 style={{textAlign: "center"}}>{todo.text}</h1>
                </Row>
                <Row className="justify-content-md-center">
                    <Card className="mt-3">
                        <Card.Body>
                            <Card.Text>{todo.description}</Card.Text>
                        </Card.Body>
                    </Card>
                </Row>
            </Container>
        </div>
    );
};

export default TodoDetail;
