// src/pages/TodoPage.js
import React, { useState, useEffect } from 'react';
import bcrypt from 'bcryptjs-react';
import axios from 'axios';
import Cookies from 'js-cookie';
import TodoList from '../components/TodoList';
import AddTodoPopup from '../components/AddTodoPopup';
import AppNavbar from '../components/Navbar'; // Import the Navbar component
import Button from 'react-bootstrap/Button';
import './TodoPage.css';

const API_URL = 'https://api.gmcobel.com/todos';
const CATEGORY_API_URL = 'https://api.gmcobel.com/category';
const DAILY_API_URL = 'https://api.gmcobel.com/dailytodos';

axios.interceptors.request.use(
  config => {
    config.headers['password'] = `${Cookies.get('password')}`;
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

const TodoPage = ({ correctPasswordHash }) => {
  const [todos, setTodos] = useState([]);
  const [dailyTodos, setDailyTodos] = useState([]);
  const [categories, setCategories] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [isDailyMode, setIsDailyMode] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);

  useEffect(() => {
    const savedPassword = Cookies.get('password');

    if (savedPassword && bcrypt.compareSync(savedPassword, correctPasswordHash)) {
      fetchTodos();
      fetchDailyTodos();
      fetchCategories();
    }
  }, [correctPasswordHash]);

  const fetchTodos = async () => {
    try {
      const response = await axios.get(API_URL);
      setTodos(response.data);
    } catch (error) {
      console.error('Error fetching todos:', error);
    }
  };

  const fetchDailyTodos = async () => {
    try {
      const response = await axios.get(DAILY_API_URL);
      setDailyTodos(response.data);
    } catch (error) {
      console.error('Error fetching daily todos:', error);
    }
  };

  const fetchCategories = async () => {
    try {
      const response = await axios.get(CATEGORY_API_URL);
      setCategories(response.data);
    } catch (error) {
      console.error('Error fetching categories:', error);
    }
  };

  const handleAddTodo = async (newTodo) => {
    try {
      const response = await axios.post(API_URL, { todo: newTodo });
      setTodos(response.data);
    } catch (error) {
      console.error('Error adding todo:', error);
    }
  };

  const handleAddDailyTodo = async (newTodo) => {
    try {
      const response = await axios.post(DAILY_API_URL, { todo: newTodo });
      setDailyTodos(response.data);
    } catch (error) {
      console.error('Error adding daily todo:', error);
    }
  };

  const handleAddCategory = async (categoryName) => {
    // Function to add category
    try {
      const response = await axios.post(CATEGORY_API_URL, { category: categoryName });
      setCategories(response.data);
    } catch (error) {
      console.error('Error adding category:', error);
    }
  }

  const handleMarkAsDone = async (index) => {
    try {
      const response = await axios.delete(`${API_URL}/${index}`);
      setTodos(response.data);
    } catch (error) {
      console.error('Error deleting todo:', error);
    }
  };

  const handleDeleteDailyTodo = async (index) => {
    try {
      const response = await axios.delete(`${DAILY_API_URL}/${index}`);
      setDailyTodos(response.data);
    } catch (error) {
      console.error('Error deleting daily todo:', error);
    }
  }

  const handleDeleteCategory = async (index) => {
    // Function to delete category
    try {
      const response = await axios.delete(`${CATEGORY_API_URL}/${index}`);
      if (selectedCategory && selectedCategory.id === index) {
        setSelectedCategory(null);
      }
      setCategories(response.data);
    } catch (error) {
      console.error('Error deleting category:', error);
    }
  }

  const handleToggleMode = () => {
    setIsDailyMode(!isDailyMode);
  };

  const handleFilterByCategory = (category) => {
    // Function to handle filtering by category
    setSelectedCategory(category);
  };

  const handleStartDaily = () => {
    // Function to start daily tasks, just call api_url/copy
    axios.post(`${API_URL}/copy`)
      .then(response => {
        setTodos(response.data);
      })
      .catch(error => {
        console.error('Error copying daily todos:', error);
      });
  };

  return (
    <div className="TodoPage">
      <AppNavbar
        onToggleMode={handleToggleMode}
        onFilterByCategory={handleFilterByCategory}
        onStartDaily={handleStartDaily}
        isDailyMode={isDailyMode}
        categories={categories}
        onAddCategory={handleAddCategory}
        onDeleteCategory={handleDeleteCategory}
        selectedCategory={selectedCategory}
      />
      <h1>{isDailyMode ? "Daily List" : "To-Do List"}</h1>
      {selectedCategory && <h3>{selectedCategory.name}</h3>}
      <TodoList
        todos={isDailyMode ? dailyTodos : todos}
        onMarkAsDone={isDailyMode ? handleDeleteDailyTodo : handleMarkAsDone}
        isDailyMode={isDailyMode}
        selectedCategory={selectedCategory}
      />
      <br />
      <Button variant="primary" onClick={() => setShowPopup(true)}>
        {isDailyMode ? 'Add Daily Task' : '+'}
      </Button>
      {showPopup && (
        <AddTodoPopup
          onAddTodo={isDailyMode ? handleAddDailyTodo : handleAddTodo}
          categories={categories}
          onClose={() => setShowPopup(false)}
          show={showPopup}
        />
      )}
    </div>
  );
};

export default TodoPage;
