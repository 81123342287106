import React from 'react';
import { Dropdown, Button } from 'react-bootstrap';
import { XCircle } from 'react-bootstrap-icons';

const CategoryDropdownItem = ({ category, isActive, onSelect, onDelete }) => {
  const handleDelete = (e) => {
    e.stopPropagation(); // Prevent the onSelect from being triggered
    onDelete(category.id);
  };

  return (
    <Dropdown.Item 
      active={isActive} 
      onClick={() => onSelect(category)}
      style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', backgroundColor: isActive ? category.color : '' }}
    >
      <span>{category.name}</span>
      <Button variant="link" onClick={handleDelete} style={{ padding: 0 }}>
        <XCircle color="red" />
      </Button>
    </Dropdown.Item>
  );
};

export default CategoryDropdownItem;
