import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

const AddCategoryPopup = ({ show, onClose, onAddCategory }) => {
  const [newCategory, setNewCategory] = useState('');

  const handleAddCategory = (event) => {
    event.preventDefault(); // Prevent default form submission
    if (newCategory.trim() !== '') {
      onAddCategory(newCategory);
      setNewCategory('');
      onClose();
    }
  };

  return (
    <Modal show={show} onHide={onClose} backdrop="static" size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Header closeButton>
        <Modal.Title>Add New Category</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleAddCategory} id="category-form">
          <Form.Group controlId="formNewCategory">
            <Form.Label>Category Name</Form.Label>
            <Form.Control
              type="text"
              value={newCategory}
              onChange={(e) => setNewCategory(e.target.value)}
              placeholder="Enter new category"
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose}>Close</Button>
        <Button variant="primary" type="submit" form="category-form">Add Category</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddCategoryPopup;
