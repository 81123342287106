import React, { useState, useEffect, useRef } from 'react';
import ProgressBar from 'react-bootstrap/ProgressBar';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { Link } from 'react-router-dom';
import './TodoItem.css';

const TodoItem = ({ todo, index, onMarkAsDone }) => {
  const [isChecked, setIsChecked] = useState(false);
  const [progress, setProgress] = useState(0);
  const intervalRef = useRef(null);

  useEffect(() => {
    if (isChecked) {
      setProgress(0);
      intervalRef.current = setInterval(() => {
        setProgress((prevProgress) => {
          if (prevProgress >= 100) {
            clearInterval(intervalRef.current);
            onMarkAsDone(index);
            return 100;
          }
          return prevProgress + 1;
        });
      }, 30); // Adjust as needed for the speed of the progress bar
    } else {
      setProgress(0);
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    }
    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, [isChecked, index, onMarkAsDone]);

  const handleChange = () => {
    setIsChecked(!isChecked);
  };

  return (
    <li className="list-group-item todo-item">
      <Row className="align-items-center">
        <Col xs="auto" style={{ maxWidth: '30px', minWidth: '30px'}}>
          <input
            className="form-check-input"
            type="checkbox"
            checked={isChecked}
            onChange={handleChange}
          />
        </Col>
        <Col xs="auto" className="todo-time" style={{ maxWidth: '60px', minWidth: "60px" }}>
          {todo.recommendedTime || '--:--'}
        </Col>
        <Col className="todo-text">
          {todo.description ? (
            <Link to={`/todo/${todo.id}`}>{todo.text}</Link>
          ) : (
            <span>{todo.text}</span>
          )}
        </Col>
      </Row>
      <Row>
        <Col xs="auto" className="flex-grow-1">
          {isChecked && (
            <ProgressBar
              now={progress / 0.5}
              striped
              animated
              variant="info"
              style={{ height: '20px', width: '100%' }}
            />
          )}
        </Col>
      </Row>
    </li>
  );
};

export default TodoItem;
