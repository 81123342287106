import React from 'react';
import TodoItem from './TodoItem';
import DailyTodoItem from './DailyTodoItem';
import ListGroup from 'react-bootstrap/ListGroup';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import './TodoList.css';

const TodoList = ({ todos, onMarkAsDone, isDailyMode, selectedCategory }) => {
  // Sort todos by recommendedTime, with items without recommendedTime having the lowest priority
  const sortedTodos = todos.sort((a, b) => {
    if (!a.recommendedTime) return 1;
    if (!b.recommendedTime) return -1;
    return a.recommendedTime.localeCompare(b.recommendedTime);
  });

  return (
    <Container className="todo-container">
      <ListGroup as="ul">
        <li className="todo-header list-group-item todo-item">
          <Row className="align-items-center">
            <Col xs="auto" style={{ maxWidth: '30px', minWidth: '30px', textAlign: 'center'}}></Col>
            <Col xs="auto" className="todo-time" style={{ maxWidth: '60px', minWidth: "60px" }}>Time</Col>
            <Col className="todo-text">Description</Col>
          </Row>
        </li>
        {sortedTodos.map((todo, index) => (
          isDailyMode ? (
            <DailyTodoItem key={todo.id} todo={todo} index={todo.id} onMarkAsDone={onMarkAsDone} />
          ) : (
            (!selectedCategory || (selectedCategory && parseInt(selectedCategory.id) === parseInt(todo.category))) && ( <TodoItem key={todo.id} todo={todo} index={todo.id} onMarkAsDone={onMarkAsDone} />)
          )
        ))}
      </ListGroup>
    </Container>
  );
};

export default TodoList;
