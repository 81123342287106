import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import './TodoItem.css';

const DailyTodoItem = ({ todo, index, onMarkAsDone }) => {

  const handleDelete = () => {
    onMarkAsDone(index);
  };

  return (
    <li className="list-group-item todo-item">
      <Row className="align-items-center">
        <Col xs="auto">
          <FontAwesomeIcon 
            icon={faTrash} 
            className="delete-icon" 
            onClick={handleDelete} 
          />
        </Col>
        {todo.recommendedTime && (
          <Col xs="auto" className="todo-time">
            <span>{todo.recommendedTime}</span>
          </Col>
        )}
        <Col className="todo-text">
          <span>{todo.text}</span>
        </Col>
      </Row>
    </li>
  );
};

export default DailyTodoItem;
