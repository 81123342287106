import React, { useState, useEffect } from 'react';
import bcrypt from 'bcryptjs-react';
import Cookies from 'js-cookie';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'; // Import routing components
import TodoPage from './pages/TodoPage';
import TodoDetail from './pages/TodoDetail'; // Import the TodoDetail component
import './App.css';

function App() {
  const correctPasswordHash = process.env.REACT_APP_PASSWORD_HASH;
  const [password, setPassword] = useState('');
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    const savedPassword = Cookies.get('password');
    if (savedPassword && bcrypt.compareSync(savedPassword, correctPasswordHash)) {
      setIsAuthenticated(true);
    }
  }, [correctPasswordHash]);

  const handleSubmit = (event) => {
    event.preventDefault();
    const isMatch = bcrypt.compareSync(password, correctPasswordHash);

    if (isMatch) {
      Cookies.set('password', password, { expires: 7 }); // Save password for 7 days
      setIsAuthenticated(true);
    } else {
      alert('Incorrect password');
    }
  };

  return (
    <div className="App">
      <script src="https://cdn.jsdelivr.net/npm/bootstrap-dark-5@1.1.3/dist/js/darkmode.min.js"></script>
      <link href="https://cdn.jsdelivr.net/npm/bootstrap-dark-5@1.1.3/dist/css/bootstrap-dark.min.css" rel="stylesheet"/>
      {!isAuthenticated ? (
        <form onSubmit={handleSubmit}>
          <label>
            Password:
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </label>
          <button type="submit">Enter</button>
        </form>
      ) : (
        <Router>
          <Routes>
            <Route path="/todo/:id" element={<TodoDetail />} />
            <Route path="/" element={<TodoPage correctPasswordHash={correctPasswordHash} />} />
          </Routes>
        </Router>
      )}
    </div>
  );
}

export default App;
