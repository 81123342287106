import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

const AddTodoPopup = ({ onAddTodo, onClose, show, categories }) => {
  const [newTodo, setNewTodo] = useState('');
  const [recommendedTime, setRecommendedTime] = useState('');
  const [description, setDescription] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('');

  const handleAddTodo = (event) => {
    event.preventDefault();
    if (newTodo.trim() !== '') {
      onAddTodo({ text: newTodo, recommendedTime, description, category: selectedCategory });
      setNewTodo('');
      setRecommendedTime('');
      setDescription('');
      setSelectedCategory('');
      onClose();
    }
  };

  return (
    <Modal show={show} onHide={onClose} backdrop="static" size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Header closeButton>
        <Modal.Title>New To-Do</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleAddTodo} id="modal-form">
          <Form.Group controlId="formTodoText">
            <Form.Label>Text</Form.Label>
            <Form.Control
              type="text"
              value={newTodo}
              onChange={(e) => setNewTodo(e.target.value)}
              placeholder="Enter your to-do"
              maxLength="20"
              required
            />
          </Form.Group>
          <Form.Group controlId="formRecommendedTime" className="mt-3">
            <Form.Label>Time</Form.Label>
            <Form.Control
              type="time"
              value={recommendedTime}
              onChange={(e) => setRecommendedTime(e.target.value)}
              placeholder="Enter recommended time"
            />
          </Form.Group>
          <Form.Group controlId="formDescription" className="mt-3">
            <Form.Label>Description</Form.Label>
            <Form.Control
              as="textarea"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              placeholder="Enter description"
              maxLength="2000"
            />
          </Form.Group>
          <Form.Group controlId="formCategory" className="mt-3">
            <Form.Label>Category</Form.Label>
            <Form.Select
              value={selectedCategory}
              onChange={(e) => setSelectedCategory(e.target.value)}
            >
              <option value="">Select category</option>
              {categories.map((category, index) => (
                <option key={category.id} value={category.id}>{category.name}</option>
              ))}
            </Form.Select>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose}>Close</Button>
        <Button variant="primary" type="submit" form="modal-form">Add</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddTodoPopup;
